export default (function(window, document) {
  if (document.cookie.indexOf('apetina-visited=1') != -1) {
    return
  }
  
  const language = document.documentElement.getAttribute('lang')
  if (language == 'pl' || language == 'en-GB') {
    const date = new Date()
    date.setTime(date.getTime() + 2 * 365 * 24 * 60 * 60 * 1000)
    document.cookie = `apetina-visited=1; expires=${  date.toUTCString()  }; path=/`
    return
  }
  
  const path = window.location.pathname
  const maxSlashes = path[path.length - 1] == '/' ? 2 : 1
  if ((path.match(/\//g) || []).length > maxSlashes) {
    return
  }
  
  const request = new XMLHttpRequest()
  request.open('GET', `/api/redirect?language=${  language}`, true)
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      if (request.responseText) {
        window.location.href = request.responseText
      }
    }
  }
  request.send()
})
