import checkRedirect from './inline/check-redirect'
import fontObserver from './inline/font-observer'
import loadFont from './inline/load-font'
import loadLogo from './inline/load-logo'
import gtm from './inline/gtm'

import './vendors/modernizr.custom'
import PromisePolyfill from './vendors/promise'

(function(window) {
  const { document } = window

  if (!window.Promise) {
    window.Promise = PromisePolyfill
  }
  // NOTE: cache SVG is omitted as it is now obsolete
  // NOTE: load loadFont('custom_fonts' ...) has been omitted as it's no longer in use
  // const lang = document.documentElement.getAttribute('lang')
  // const fontFile = lang == 'pl' ? 'font_pl.css' : 'font.css'

  checkRedirect(window, document)
  loadFont(document)
  fontObserver()
  loadLogo(window, document)
  gtm(window, document, 'script', 'dataLayer', 'GTM-W34X5M')

}(window))
