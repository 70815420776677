import FontFaceObserver from 'fontfaceobserver'

export default () => {
	// Optimization for Repeat Views
	if( sessionStorage.foftFontsLoaded ) {
		document.documentElement.className += " font-loaded"
		return
	}

	const fontBold = new FontFaceObserver('din_condensedbold', {
		weight: 'normal'
	})

	const fontRegular = new FontFaceObserver('dincond-regularregular', {
		weight: 'normal'
	})

	Promise.all([fontBold.load(), fontRegular.load()]).then(function () {
		document.documentElement.className += " font-loaded"

		// Optimization for Repeat Views
		sessionStorage.foftFontsLoaded = true
	})
}
