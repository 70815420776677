export default (window, document) => {
  let logoRequest
  const sessionCookie = getLogoFromCookie('apetina-logo-session')
  let cookie = window.location.hash.slice(1) == 'arla-logo' ? 'arla' : null

  if (cookie && !sessionCookie) {
    const date = new Date()
    date.setTime(date.getTime() + 30 * 60 * 1000)
    document.cookie = `apetina-logo-session=arla; expires=${  date.toUTCString()  }; path=/`
  }

  cookie = cookie || sessionCookie || getLogoFromCookie('apetina-logo')

  function getLogoFromCookie(name) {
    return decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${  encodeURIComponent(name).replace(/[-.+*]/g, "\\$&")  }\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1")) || null
  }

  if (!cookie) {
    try {
        logoRequest = new XMLHttpRequest()
        logoRequest.open('GET', '/api/logo', true)
        logoRequest.onload = function () {
          let data
          if (logoRequest.status >= 200 && logoRequest.status < 400) {
            data = logoRequest.responseText
            document.documentElement.className += ` ${  data.replace(/"/g, '')}`
          }
        }
        logoRequest.send()
      }
      catch (e) {
        document.documentElement.className += ' apetina'
      }
  } else if(cookie === 'apetina' || cookie === 'arla') {
      document.documentElement.className += ` ${  cookie}`
    } else {
      document.documentElement.className += ' apetina'
    }
}
